{
  "name": "grc-frontend",
  "version": "0.1.1-78",
  "engines": {
    "node": "^20.9.0"
  },
  "scripts": {
    "ng": "ng",
    "start": "ng serve --configuration development",
    "start:qa": "ng serve --configuration qa",
    "start:prod": "ng serve --configuration prod",
    "start:staging": "ng serve --configuration staging",
    "build": "ng build",
    "build:development": "ng build --configuration development",
    "build:qa": "ng build --configuration qa",
    "build:production": "ng build --configuration production",
    "build:staging": "ng build --configuration staging",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "eslint 'src/**/*.ts'",
    "lint:fix": "eslint --fix 'src/**/*.ts'",
    "format": "prettier --write 'src/**/*.ts' 'src/**/*.html' 'src/**/*.less'"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.0.0",
    "@angular/common": "^17.0.0",
    "@angular/compiler": "^17.0.0",
    "@angular/core": "^17.0.0",
    "@angular/forms": "^17.0.0",
    "@angular/platform-browser": "^17.0.0",
    "@angular/platform-browser-dynamic": "^17.0.0",
    "@angular/router": "^17.0.0",
    "@ant-design/icons-angular": "^17.0.0",
    "@tinymce/tinymce-angular": "^7.0.0",
    "@types/googlemaps": "^3.43.3",
    "ng-zorro-antd": "^17.4.0",
    "rxjs": "~7.8.0",
    "tinymce": "^7.0.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.7",
    "@angular-eslint/builder": "17.1.1",
    "@angular-eslint/eslint-plugin": "17.1.1",
    "@angular-eslint/eslint-plugin-template": "17.1.1",
    "@angular-eslint/schematics": "17.1.1",
    "@angular-eslint/template-parser": "17.1.1",
    "@angular/cli": "^17.0.7",
    "@angular/compiler-cli": "^17.0.0",
    "@angular/localize": "^17.0.7",
    "@types/jasmine": "~5.1.0",
    "@typescript-eslint/eslint-plugin": "6.13.1",
    "@typescript-eslint/parser": "6.13.1",
    "eslint": "^8.54.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "prettier": "^3.2.4",
    "typescript": "~5.2.2"
  }
}
